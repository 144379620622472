body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $f;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

#tab-description {
	.row {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		&:before, &:after {
			content: none;
		}
		& > div {
			margin: 0 10px 30px;
			border-radius: 5px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
			width: calc(100% / 3 - 20px);
			@media (max-width: 1100px) {
				width: calc(100% / 2 - 16px); 
				margin: 0 8px 30px;
			}
			@media (max-width: 600px) {
				width: 100%;
			}
			.caption {
				h3 {
					padding: 25px 20px 20px;
					font-size: 16px;
					line-height: 21px;
					font-weight: 600;
					margin: 0;
				}
				p {
					padding: 0 20px 20px;
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}
}

/* search */
#searchtop {
	input.form-control {
		border: none;
		border-bottom: 2px solid $tc;
		height: 42px;
		width: 165px;
		@media (min-width: 1400px) {
			width: 249px;
		}
		@include placeholder($tc);
	}
	.input-group-btn {
		color: $wh;
		padding: 0;
		text-align: center;
		height: 48px;
		@include transition(all 0.2s ease-in-out);
		&:hover {
			.svg-icon {
				@include size(25px, 25px);
			}
		}
	}
	.btn-search {
		display: inline-block;
		background: transparent;
		font-size: 14px !important;
		font-weight: 600;
		border-bottom: 2px solid $tc;
		cursor: pointer;
		color: $tc;
		@include transition(all 0.2s ease-in-out);
	}
}
.form-control::-moz-placeholder {
	color: $tc;
}

// compare page
.page-product-compare {
	.btn-primary {
		&.danger {
			background-color: #f6821f;
			border-color: #f2750a;
			&:hover {
				background-color: #d96909;
				border-color: #b75807;
			}
		}
	}
}

// compare in head
#compare-count {
	.compare-link {
		display: flex;
		align-items: center;
		padding-left: 15px;
		.icon {
			@include size(23px, 23px);
			margin-right: 15px;
		}
		.text-count {
			color: #222222;
			font-weight: 600;
			font-size: 14px;
		}
		&:hover {
			.text-count {
				color: #0952A5;
			}
		}
	}
}

// module cart
#cart {
	width: auto;
	.cart-inner {
		height: 30px;
	}
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
		text-decoration: none;
		&:hover {
			#cart-total {
				color: $tc;
			}
		}
	}
	.icon-cart {
		background: transparent;
		display: flex;
		align-items: center;
		position: relative;
		text-align: center;
		padding: 0 15px 0 0;
		height: 31px;
		i {
			font-size: 36px;
			line-height: 28px;
		}
		svg {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #0952A5;
			@include size(23px, 23px);
		}

	}
	.wrap-cart {
		display: flex;
		min-width: 50%;
		&:last-child {
			width: max-content;
			display: flex;
			align-items: center;
		}
		span {
			position: relative;
		}
		#cart-total {
			font-family: $f;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			font-size: 14px;
			text-transform: uppercase;
		}
		.badge {
			line-height: 20px;
			z-index: 99;
			@include colors($tc, $tc2);
			font-weight: 600;
		}
	}
	.price-cart {
		color: $tc;
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include border-radius($border-color, 3px)
		}
	}
}

/* product page */
.page-product {
	#tab-specification {
		& > .table.table-bordered > thead {
			display: none;
		}
	}
	strong {
		color: $tc;
	}
	.product-view {
		.title-product {
			width: 100%;
			text-align: left;
		}
		.list-unstyled {
			li {
				font-size: 16px;
				margin-bottom: 10px;
				.text-primary {
					display: none;
				}
				span:first-child {
					margin-right: 5px;
				}
				span:last-child {
					font-size: 14px;
				}
			}
		}
		.price {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}