@mixin border-exclude-top($border-deep, $border-type , $border-color ) {
	border-bottom: $border-deep $border-type $border-color;
	border-left: $border-deep $border-type $border-color;
	border-right: $border-deep $border-type $border-color;
}

@mixin border-exclude-bottom($border-deep, $border-type , $border-color ) {
	border-top: $border-deep $border-type $border-color;
	border-left: $border-deep $border-type $border-color;
	border-right: $border-deep $border-type $border-color;
}

@mixin border-exclude-left($border-deep, $border-type , $border-color ) {
	border-top: $border-deep $border-type $border-color;
	border-bottom: $border-deep $border-type $border-color;
	border-right: $border-deep $border-type $border-color;
}

@mixin border-exclude-right($border-deep, $border-type , $border-color ) {
	border-top: $border-deep $border-type $border-color;
	border-bottom: $border-deep $border-type $border-color;
	border-left: $border-deep $border-type $border-color;
}

@mixin rounded-corners($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
	border-radius: $radius;
}

@mixin clearboxstyle() {
	background: none;
	border: none;
}

@mixin clearfloat() {
	float: none;
	width: 100%;
}

@mixin transform-style($transform-style) {
	-webkit-transform-style: $transform-style;
	-moz-transform-style: $transform-style;
	-ms-transform-style: $transform-style;
	-o-transform-style: $transform-style;
	transform-style: $transform-style;
}

@mixin transform-rotate($value) {
	-webkit-transform: rotate($value);
	-moz-transform: rotate($value);
	-o-transform: rotate($value);
	-ms-transform: rotate($value);
	transform: rotate($value);
}

@mixin backface-visibility($backface-visibility) {
	backface-visibility: $backface-visibility; /* W3C */
	-webkit-backface-visibility: $backface-visibility; /* Safari & Chrome */
	-moz-backface-visibility: $backface-visibility; /* Firefox */
	-ms-backface-visibility: $backface-visibility; /* Internet Explorer */
	-o-backface-visibility: $backface-visibility; /* Opera */
}

@mixin animation-theme($animation-duration, $animation-fill-mode, $animation-name) {
	-webkit-animation-duration: $animation-duration;
	-moz-animation-duration: $animation-duration;
	-ms-animation-duration: $animation-duration;
	-o-animation-duration: $animation-duration;
	animation-duration: $animation-duration;

	-webkit-animation-fill-mode: $animation-fill-mode;
	-moz-animation-fill-mode: $animation-fill-mode;
	-ms-animation-fill-mode: $animation-fill-mode;
	-o-animation-fill-mode: $animation-fill-mode;
	animation-fill-mode: $animation-fill-mode;

	-webkit-animation-name: $animation-name;
	-moz-animation-name: $animation-name;
	-ms-animation-name: $animation-name;
	-o-animation-name: $animation-name;
	animation-name: $animation-name;
}

@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	-ms-perspective: $perspective;
	-moz-perspective: $perspective;
	-o-perspective: $perspective;
	perspective: $perspective;
}

/**
* Transition-timing-function property@mixin 
*/
@mixin transition-timing-function($timing-function) {
	-moz-transition-timing-function: $timing-function;
	-o-transition-timing-function: $timing-function;
	-webkit-transition-timing-function: $timing-function;
	transition-timing-function: $timing-function;
}

@mixin transform($arguments) {
	-webkit-transform: $arguments;
	-moz-transform: $arguments;
	-ms-transform: $arguments;
	-o-transform: $arguments;
}

@mixin notransform() {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}

@mixin appearance($arguments) {
	appearance: $arguments;
	-moz-appearance: $arguments;
	-ms-appearance: $arguments;
	-o-appearance: $arguments;
	-webkit-appearance: $arguments;
}



/*background RGBA
============================================*/
@mixin rgba($colour, $alpha) {
	$alphaColour: hsla(hue($colour), saturation($colour), lightness($colour), $alpha);
	$ieAlphaColour: argb($alphaColour);
	background-color: $colour;
	background-color: $alphaColour;
	zoom: 1;
	background-color: transparent \9
;

}

@mixin border-rgba($colour, $alpha) {
	$alphaColour: hsla(hue($colour), saturation($colour), lightness($colour), $alpha);
	$ieAlphaColour: argb($alphaColour);
	border-color: $colour;
	border-color: $alphaColour;
	zoom: 1;
	border-color: transparent \9
;

}

//copyright

//sub heading (h2,h3) define
@mixin sub-heading {
	float: left;
	color: $block-heading-color;
	text-transform: uppercase;
	font: 600 14px/20px $font-custom;
	padding: 8px 15px;
	margin: 0 0 20px;
	min-width: 120px;
	position: relative;
	background: $theme-bg-default;
}

@mixin sub-heading-before {
	height: 0;
	width: 0;
	right: 45%;
	top: 100%;
	content: "";
	position: absolute;
	border: 4px solid transparent;
	border-top-color: $theme-bg-default;
}

//background
@mixin background-hover {
	color: $base-text-color;
	background: rgba(228, 50, 40, 0.3);
}

/*inline-block
============================================*/

@mixin inline-block() {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

@mixin box-shadow($string) {
	-webkit-box-shadow: $string;
	-moz-box-shadow: $string;
	-o-box-shadow: $string;
	box-shadow: $string;
}

// Sizing shortcuts
@mixin size($width, $height) {
	width: $width;
	height: $height;
}

// Boxs
// -------------------------

@mixin box-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
	border-color: $border;

	& > .box-heading {
		color: $heading-text-color;
		background-color: $heading-bg-color;
		border-color: $heading-border;

		+ .panel-collapse > .box-content {
			border-top-color: $border;
		}
		.badge {
			color: $heading-bg-color;
			background-color: $heading-text-color;
		}
	}
	& > .box-footer {
		+ .panel-collapse > .box-content {
			border-bottom-color: $border;
		}
	}
}

/// button variant outline
@mixin button-variant-outline($color, $background, $border, $colorhover, $bghover, $borderhover ) {
	color: $color;
	background-color: $background;
	border-color: $border;

	&:hover,
	&:focus,
	&:active,
	&.active {
		color: $colorhover;
		background-color: $bghover;
		border-color: $borderhover;
	}
	.open & {
		&.dropdown-toggle {
			color: $colorhover;
			background-color: $bghover;
			border-color: $borderhover;
		}
	}
	&:active,
	&.active {
		background-image: none;
	}
	.open & {
		&.dropdown-toggle {
			background-image: none;
		}
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: $background;
			border-color: $border;
		}
	}

	.badge {
		color: $background;
		background-color: $color;
	}
}

// Block
// -------------------------
@mixin block-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
	border-color: $border;

	& .#{$block-heading-selector} {
		color: $heading-text-color;
		background-color: $heading-bg-color;
		border-color: $heading-border;
		+ .#{$block-prefix}-collapse .#{$block-content-selector} {
			border-top-color: $border;
		}
	}
	& > .#{$block-prefix}-footer {
		+ .#{$block-prefix}-collapse .#{$block-prefix}-body {
			border-bottom-color: $border;
		}
	}
}

/****/
@mixin container-layout-variant($color, $background, $linkcolor,  $link-hover-color ) {
	background: $background;
	color: $color;
	a {
		// color:$linkcolor;
		&:hover {
			color: $link-hover-color;
		}
	}
}

@mixin text-shadow() {
	a {
		&:hover {
			text-shadow: 0 0 15px #FFFFFF;
			transform: scale(1.05);
		}
	}
}

// effect
// prefix declarations
@mixin prefixed($property, $value) {
	@if $webkit == true {
		-webkit-#{$property}: #{$value};
	}

	@if $moz == true {
		-moz-#{$property}: #{$value};
	}

	@if $ms == true {
		-ms-#{$property}: #{$value};
	}

	@if $o == true {
		-o-#{$property}: #{$value};
	}

	#{$property}: #{$value};
}

// prefix keyframes
@mixin keyframes($name) {
	@if $webkit == true {
		@-webkit-keyframes #{$name} {
			@content;
		}
	}

	@if $moz == true {
		@-moz-keyframes #{$name} {
			@content;
		}
	}

	@if $ms == true {
		@-ms-keyframes #{$name} {
			@content;
		}
	}

	@if $o == true {
		@-o-keyframes #{$name} {
			@content;
		}
	}

	@keyframes #{$name} {
		@content;
	}
}

@mixin hardwareAccel() {
	// Improve performance on mobile/tablet devices
	@include prefixed(transform, translateZ(0));
}

@mixin improveAntiAlias() {
	// Improve aliasing on mobile/tablet devices
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin fontSmooth() {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
}

@mixin forceBlockLevel() {
	// Transforms need to be block-level to work
	// display: inline-block;
	display: block;
	vertical-align: middle;
}

@mixin hacks() {
	@include forceBlockLevel();
	@include hardwareAccel();
	@include improveAntiAlias();
	@include fontSmooth();
}

/* Curl Top Right */
@mixin curl-top-right {
	@include hacks();
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		content: '';
		height: 0;
		width: 0;
		top: 0;
		right: 0;
		background: $revealAreaColor; /* IE9 */
		background: linear-gradient(
						225deg,
						$revealAreaColor 45%,
						$curlLineColor 50%,
						$curlTransitionColor 56%,
						$curlLastColor 80%
		);
		box-shadow: -1px 1px 1px rgba(0, 0, 0, .4);
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, "width, height");
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		width: $curlWidth;
		height: $curlHeight;
	}
}

@mixin button-inverse( $suffixclass,  $color ,$background ) {

	&.btn-#{$suffixclass} {
		&:hover {
			color: $color;
			background: transparent;
		}
	}
}

@mixin button-outline( $suffixclass, $color, $hovercolor ) {
	background: transparent;
	&.btn-#{$suffixclass} {
		color: $color;
		&:hover {
			color: $hovercolor;
		}
	}
}

@mixin button-3d($suffixclass, $height3d ,$color3d) {
	border: 0;
	@if ($suffixclass == "empty") {
		box-shadow: 0 $height3d $color3d inset;
		-o-box-shadow: 0 $height3d $color3d inset;
		-moz-box-shadow: 0 $height3d $color3d inset;
		-webkit-box-shadow: 0 $height3d $color3d inset;
		-ms-box-shadow: 0 $height3d $color3d inset;
	} @else {
		&.btn-#{$suffixclass} {
			box-shadow: 0 $height3d $color3d inset;
			-o-box-shadow: 0 $height3d $color3d inset;
			-moz-box-shadow: 0 $height3d $color3d inset;
			-webkit-box-shadow: 0 $height3d $color3d inset;
			-ms-box-shadow: 0 $height3d $color3d inset;
		}
	}
}

// icon variant inverse

@mixin icons-inverse( $suffixclass,  $color ,$background ) {

	&.icons-#{$suffixclass} {
		&:hover {
			color: $color;
			background: transparent;
		}
	}
}

// icon variant outline

@mixin icons-outline( $suffixclass, $color, $hovercolor ) {
	&.icons-#{$suffixclass} {
		background: transparent;
		color: $color;
		&:hover {
			color: $hovercolor;
		}
	}
}

//mixin animation
@mixin animation($animate...) {
	$max: length($animate);
	$animations: '';

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "};
		}
	}
	-webkit-animation: $animations;
	-moz-animation: $animations;
	-o-animation: $animations;
	animation: $animations;
}

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}

/**absolute**/
@mixin element-absolute($border-color) {
	&:before {
		position: absolute;
		content: "";
		@include size(10px, 10px);
		display: block;
		border-top: 1px solid $border-color;
		border-left: 1px solid $border-color;
		left: 0;
		top: 0;
		z-index: 2;
	}
	&:after {
		position: absolute;
		content: "";
		@include size(10px, 10px);
		display: block;
		border-bottom: 1px solid $border-color;
		border-right: 1px solid $border-color;
		bottom: 0;
		right: 0;
		z-index: 2;
	}
}

@mixin border-radius($border-color,$value) {
	border: 1px solid $border-color;
	@include rounded-corners($value);
}