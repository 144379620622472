// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
	&:hover {
		.tp-rightarrow {
			right: 20px !important;
		}
		.tp-leftarrow {
			left: 20px !important;
		}
	}
}

.tparrows {
	position: absolute;
	top: 50% !important;
	z-index: 9999;
	cursor: pointer;
	display: block;
	@include size(45px, 45px);
	@include transition(all 0.3s ease-in-out 0s);
	font-family: "FontAwesome";
	font-size: 0;
	&:before {
		content: "";
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 12;
		@include size(45px, 45px);
		font-size: 40px;
		line-height: 46px;
		@include transition(all 0.35s ease 0s);
		display: block;
	}
	&.tp-leftarrow {
		opacity: 0;
		@include colors($tc2, $tc);
		&:before {
			content: '\f111';
		}
	}
	&.tp-rightarrow {
		opacity: 0;
		@include colors($tc2, $tc);
		&:before {
			content: '\f112';
			background-position: -50px -2px;
		}
	}
}
.bannercontainer {
	&:hover {
		.tp-rightarrow, .tp-leftarrow {
			opacity: 1;
		}
	}
}
.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: $theme-color;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: $theme-color;
}

.effect-carousel-v2 {
	.carousel-controls-v1 {
		position: absolute;
		right: 0;
		.carousel-control {
			@include opacity(0);
			@include transition(all 0.5s ease-in-out 0s);
			top: -20px;
			&.left {
				left: -63px;
			}
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			top: 2px;
		}
	}
}

.effect-carousel {
	.carousel-control {
		@include opacity(0);
		@include transition(all 0.5s ease-in-out 0s);
		&.left {
			left: -45px;
		}
		&.right {
			right: -45px;
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			&.left {
				left: 0;
			}
			&.right {
				right: 0;
			}
		}
	}
}

/* tablist-v1 */
.tablist-v1 {
	.product-grid {
		.products-row {
			.product-col {
				&:last-child {
					border-right: 1px solid $border-color;
					@include rounded-corners(3px);
				}
			}
		}
	}
}

.featured-category {
	.panel-body {
		overflow: hidden;
		padding: 0;
	}
	ul {
		margin: 0;
		li {
			display: inline-block;
			width: 33%;
			position: relative;
			&:before {
				position: absolute;
				right: 0;
				background: $border-color;
				content: "";
				@include size(1px, 200%);
				top: -20px;
			}
			&:after {
				position: absolute;
				left: 25px;
				background: $border-color;
				content: "";
				@include size(87%, 1px);
				top: 0;
			}
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				&:after {
					content: none;
				}
			}
			&:nth-child(3n) {
				&:before {
					content: none;
				}
			}
			.image {
				margin: 5px 0 5px 25px;
			}
			.caption {
				overflow: hidden;
				padding: 30px 20px;
				h6 {
					font-weight: normal;
					font-size: $font-size-base + 2;
					a {
						color: $black;
					}
				}
			}
		}
	}
}