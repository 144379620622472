html {
	overflow-x: hidden;
}

body {
	h1, h2, h3 {
		font-family: Open Sans;
		font-style: normal;
		color: $tc2;
		text-transform: uppercase;
	}
	h {
		&1 {
			font-weight: 800;
			line-height: 45px;
		}
		&2 {
			font-weight: bold;
			line-height: 32px;
		}
		&3 {
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
		}
	}
	@media (max-width: $screen-xs-max) {
		h {
			&1 {
				font-size: 24px;
			}
			&2 {
				font-size: 20px;
			}
			&3 {
				font-size: $font-size-base;
			}
		}
		p {
			font-size: $font-size-base;
		}
	}
	p {
		font-size: $font-size-base + 2;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		line-height: normal;
		color: $tc2;
	}
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
		img {
			max-width: 100%;
		}
	}
	&.common-home #content {
		padding: 0;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

/* buttons */
.btn-primary, .btn-white, .modal-dialog .callback_button {
	height: 36px;
	line-height: 36px;
	border-radius: 18px;
	font-size: 18px;
	font-weight: 500;
	padding: 0 25px;
	text-transform: uppercase;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.btn-white {
	padding: 0 50px;
}

.btn-circle {
	display: flex;
	justify-content: center;
	align-items: center;
	@include size(40px, 40px);
	border: 0;
	border-radius: 20px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	svg path {
		fill: $tc;
	}
	&:hover {
		svg path {
			fill: $wh;
		}
	}
}

.shadow-box {
	background: $wh;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.24), 0 0 5px rgba(0, 0, 0, 0.12);
	@include rounded-corners(5px);
	padding: 25px;
}

.hidden {
	visibility: hidden;
	opacity: 0;
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

.page-themecontrol-product {
	overflow-y: hidden;
}

/*svg color*/
.st0, .st1 {
	fill: $tc;
	fill-rule: evenodd;
	clip-rule: evenodd;
}

/*
* HEADER 
*/
.svg-icon {
	@include size(22px, 22px);
}

.center {
	text-align: center;
}

.container {
	height: inherit;
}

.header-top {
	height: 95px;
	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > div:last-child {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: inherit;
			position: relative;
		}
		#logo-theme {
			width: auto;
			margin: 0 auto 0 0;
			display: flex;
			height: inherit;
			align-items: center;
			.svg-icon {
				@include size(160px, 67px);
				svg {
					fill: $tc;
				}
			}
			a {
				text-align: left;
				display: flex;
				align-items: center;
				text-decoration: none;
				span {
					font-style: normal;
					font-weight: 600;
					line-height: 21px;
					font-size: 13px;
					text-transform: uppercase;
					color: $tc;
					text-align: left;
				}
				& div:last-child {
					display: flex;
					margin-left: 20px;
					/*span {
						word-break: break-word;
						width: 160px;
					}*/
				}
			}
		}
		.topcontacts {
			display: flex;
			justify-content: center;
			.media-title {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 0 10px;
			}
			.media-body {
				display: flex;
				flex-wrap: wrap;
				font-weight: 500;
				line-height: 22px;
			}
		}
		.worktime {
			width: 245px;
			.media-body {
				font-size: 18px;
				span:last-child {
					letter-spacing: 0.3px;
				}
			}
		}
		.phones {
			margin-left: 10px;
			.svg-icon {
				@include size(28px,28px);
			}
			.media-body {
				width: 160px;
				font-weight: 600;
				font-size: 20px;
				span:first-child {
					letter-spacing: 1px;
				}
			}
		}
		#clone-search {
			display: none;
			position: relative;
			padding-bottom: 0;
			input {
				background-color: $wh;
				color: $tc;
				font-size: 15px;
				padding: 10px 10px 5px;
				width: 0px;
				opacity: 0;
				visibility: hidden;
				position: absolute;
				right: 0px;
				height: 38px;
				border-radius: unset;
				border-top: none;
				border-left: none;
				border-right: none;
				&.active_input {
					width: calc(100vw - 620px);
					@include transition(all 0.4s ease 0s);
					border-bottom: 2px solid $tc;
					opacity: 1;
					visibility: visible;
				}
			}
			button {
				border: none;
				background: $wh;
			}
		}
	}
}

.header-bottom {
	height: 50px;
	.container {
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:before, &:after {
			content: none;
		}
		.nav-search {
			#searchtop, #clone-search {
				padding-bottom: 10px;
				input {
					border-radius: 0;
				}
			}
		}
		.headercart {
			display: flex;
			align-items: center;
			text-align: right;
			justify-content: flex-end;
		}
	}
	#pav-mainnav {
		.svg-icon {
			@include size(30px, 30px);
			svg {
				width: 100%;
				stroke: $wh;
				stroke-width: 4px;
				fill: none;
			}
		}
	}
}

.header-menu {
	& > .container {
		display: flex;
		justify-content: space-between;
		&:before, &:after {
			content: none;
		}
	}
}

.mobile-logo {
	display: none;
}

.mobile-panel {
	display: none;
}

.shopping-cart-table {
	background: $white;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				background: $tc;
				span {
					color: $wh;
				}
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid $lg;
	}
	&.table {
		tr td {
			border: 1px $lg solid;
		}
	}
	.btn {
		font-size: 23px;
		padding: 0 7px;
		i {
			vertical-align: 2px;
		}
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#logo-footer .svg-icon {
	@include size(180px, 70px);
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	svg {
		fill: $wh;
	}

	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			a {
				font-size: $font-size-base;
				color: $wh;
				@include transition(all 0.4s ease 0s);
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.footer-center {
	background: $tc;
	padding: 30px 0;
	color: $wh;
	a {
		&:hover, &:active {
			text-decoration: none;
		}
	}
	.container > .row > div:first-child .list-unstyled {
		max-width: 250px;
	}
	.box {
		.box-heading {
			font-size: 18px;
			margin-bottom: 30px;
			font-weight: 600;
			text-transform: uppercase;
			@media (max-width: $screen-xs-max) {
				margin-bottom: 15px;
				margin-top: 25px;
			}
		}
		.list-unstyled:last-child li {
			padding-bottom: 10px;
			&.ft-phone {
				.footer-svg-icon {
					svg {
						@include size(25px, 30px);
					}
				}
				.contats-info-block {
					span {
						width: 100%;
						&:first-child {
							letter-spacing: 1px;
						}
					}
				}
			}
			display: flex;
			.contats-info-block {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding-left: 10px;
				color: $wh;
				max-width: 250px;
				span, a {
					&:hover {
						text-decoration: none;
					}
				}
				span:last-child {
					letter-spacing: 0.22px;
				}
			}
			/*norm*/
			.footer-svg-icon {
				display: flex;
				align-items: center;
				svg {
					@include size(25px, 25px);
					& path {
						fill: $wh;
					}
				}
			}

		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px;
		.svg-logo {
			width: 100%;
			text-align: left;
			span {
				padding: 7px 0;
				display: block;
			}
		}
	}
	.main-footer {
		display: flex;
	}
}

/* POWER BY */
#powered {
	background: $tc;
	.container {
		border-top: 1px solid $wh;
		height: 90px;
	}

	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		padding: 5px 0 0 15px;
		color: $white;
		font-size: 12px;
		font-weight: 500;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		align-items: center;
	}
	.payment-img {
		text-align: right;

		img {
			height: 61px;
		}
	}
}
.payment2row {
	display: none;
	margin: 8px 0;
}

#blackfriday {
	position: fixed;
	bottom: 0;
	width: 100%;
	background: $tc;
	z-index: 9999;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 25px;
	line-height: 50px;
	span.text {
		padding: 0 50px;
		display: inline-block;
	}
	.fa {
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -12px;
		font-size: 25px;
		cursor: pointer;
	}
}

.page-themecontrol-product {
	#blackfriday {
		display: none;
	}
}