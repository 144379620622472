//
// Badges
// --------------------------------------------------


// Base class
.badge {
  white-space: nowrap;
  vertical-align: middle;
  width: 19px;
  height: 19px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  border-radius: 100px;
  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for badges in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
  .btn-xs & {
    top: 0;
    padding: 1px 5px;
  }

  // [converter] extracted a& to a.badge

  // Account for badges in navs
  .list-group-item.active > &,
  .nav-pills > .active > a > & {
    color: $badge-active-color;
    background-color: $badge-active-bg;
  }
  .list-group-item > & {
    float: right;
  }
  .list-group-item > & + & {
    margin-right: 5px;
  }
  .nav-pills > li > a > & {
    margin-left: 3px;
  }
}

// Hover state, but only for links
a.badge {
  &:hover,
  &:focus {
    color: $badge-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}
