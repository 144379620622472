.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}
.products-block {
	.owl-carousel {
		margin: 0;
	}
}

/* ==================================================================================================================== */
/* 													product page 														*/
/* ==================================================================================================================== */

.page-product {
	.product-view {
		svg path, a svg path {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: $tc;
		}
		.title-product {
			margin: 0;
			padding-top: 0;
		}
	}

	.product-info .tab-content {
		border: none;
		padding: 0;
		margin-bottom: 40px;
		.desc-header-wrapper {
			margin-top: 80px;
			margin-bottom: 40px;
		}
		.for-send-error-press {
			font-style: italic;
			margin: 25px 0 15px;
		}
	}

	/* rating */
	.prod_rating {
		width: 100%;
		display: flex;
		align-items: center;
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px solid $lg;
		.stars {
			color: $y;
			font-size: 18px;
		}
		#scr_revs, #scr_newrev {
			margin-left: 20px;
			display: flex;
			align-items: center;
		}
	}
	/* review */
	#review_btn {
		margin-left: 150px;
	}
	#review_btn.collapsed {
		margin-left: 0;
	}
	#review {
		.review-item {
			display: flex;
			color: #222222;
			margin-bottom: 20px;
			.review-header {
				width: 150px;
				.rating .fa {
					color: $y;
					font-size: 20px;
				}
				.review-date {
					color: $dg;
					font-size: 18px;
				}
			}
			.review-main {
				flex: 1;
				color: $tc2;
				.review-author {
					margin-bottom: 20px;
				}
				.review-title {
					margin: 15px 0 7px;
				}
				.review-title, .review-author {
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
				}
				.review-text {
					font-size: 18px;
					line-height: 27px;
				}
			}
		}
	}

	/* review form */
	#form-review-container {
		margin-top: 30px;
		input, textarea {
			border-radius: 4px;
		}
		.form-group {
			margin-bottom: 15px;
			& > div {
				display: flex;
				.control-label {
					width: 150px;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					color: $tc2;
					padding: 0;
				}
				.form-control {
					flex: 1;
				}
			}
		}
		.recaptcha_container legend {
			display: none;
		}
		.set-rating {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-top: 6px;
			margin-bottom: 2px;
			height: 16px;
			i {
				font-size: 20px;
				margin: 0px;
				display: block;
				float: left;
				padding: 0px 4px 0px 0px;
				cursor: pointer;
				color: $dg;
				&.selected {
					color: $y;
				}
			}
			&:hover i {
				color: $dg;
			}
			& > i:hover, & > i:hover ~ i {
				color: $y;
			}
		}
		.recaptcha_container, .btn-white {
			margin-left: 150px;
			.control-label {
				display: none;
			}
			legend {}
		}
	}

	/* attributes */
	#tab-specification {
		.single-attr {
			display: flex;
			margin-bottom: 10px;
			.attr-name {
				display: flex;
				width: 85%;
				max-width: 600px;
				div:first-child {
					font-size: 18px;
					flex: unset;
					color: $dg;
				}
				.underlined {
					flex: 1;
					border-bottom: 1px dotted $dg;
					height: 23px;
				}
			}
			.attr-val {
				font-size: 18px;
				color: $tc2;
			}
		}
	}

	#tab-downloads {
		.download-link {
			color: $dg;
			margin: 10px 0;
			&:hover {
				color: $tc2;
			}
			.svg-icon {
				margin-right: 20px;
			}
		}
	}

	/* buttons */
	.btn-white {
		padding: 0 50px;
	}
	/* product specification */
	.product-specification {
		width: 100%;
		display: flex;
		margin-bottom: 30px;
		.first-specification-block {
			width: 50%;
			display: flex;
			flex-direction: column;
			.list-unstyled {
				li {
					span {
						&:first-child {
							font-size: 16px;
							font-weight: 600;
							text-transform: uppercase;
							color: $tc2;
						}
						&.stock {
							color: $g;
							font-size: 14px;
						}
					}
				}
			}
			.invoice {
				margin-top: 25px;
			}
		}
		.second-specification-block {
			width: 50%;
			min-width: 270px;
			.specification-block-inner {
				font-style: normal;
				width: 270px;
				.pr-top {
					height: 30px;
					.price-old {
						font-size: 16px;
						line-height: 18px;
						text-decoration-line: line-through;
						color: $tc2;
						span {
							color: inherit;
						}
					}
					.price-diff {
						@include size(60px, 20px);
						font-size: 12px;
						line-height: 18px;
						border: 1px solid $r;
						border-radius: 20px;
						padding: 3px 10px;
						margin-left: 10px;
						box-sizing: border-box;
						color: $r;
						span {
							color: inherit;
						}
					}
				}
				.price-new {
					font-weight: bold;
					font-size: 26px;
					line-height: 32px;
					text-transform: uppercase;
					color: $tc2;
				}
			}
			.cheap {
				color: $tc;
				margin-top: 15px;
			}
		}
		#mobile-specification-block {
			display: none;
		}
		#desctop-specification-block {

		}
	}
	.product-markdown-view {
		.specification-block-inner {
			@include box-shadow(none);
		}

		.specification-block-inner, .cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
		.cheaper-item, .cheaper-products {
			&__title {
				font-size: 16px;
				font-weight: 600;
			}
			&__status {
				color: #767676;
				font-weight: 600;
			}
		}
		.cheaper-item {
			padding: 20px;
			border: 1px solid $dg;
			width: 270px;
			@include rounded-corners(5px);

			&:not(.general) {
				.price-new {
					font-size: 18px;
					font-weight: 600;
				}
				.price-old {
					text-decoration: line-through;
				}
			}
			&.general {
				margin-bottom: 20px;
				border: 1px solid $tc2;
			}
			&__price {
				display: flex;
				gap: 16px;
				align-items: flex-end;
			}
			.price {
				margin-bottom: 0;

				&:hover {
					color: $tc2;
				}
				.price-new, .price-old {
					line-height: 1;
				}
			}
		}
	}
}
/* storage widget */
.storage_widget {
	& > span {
		color: $tc2 !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td:first-child span {
			background: #ffd71a !important;
			background-image: none !important;
			border: solid $tc2 1px !important;
			border-radius: 50% !important;
		}
		td:last-child span {
			background: $r !important;
			color: $wh !important;
			border-radius: 0 !important;
			font-weight: 600;
			text-transform: uppercase;
			font-size: 14px !important;
			padding: 7px 12px !important;
			font-family: "Open Sans", sans-serif!important;
			&:hover, &:active, &:focus {
				background: $tc2 !important;
			}
		}
	}
	& > div {
		font-size: 11px !important;
	}
}

.btn-primary, .btn-white, .modal-dialog .callback_button {
	height: 36px;
	line-height: 36px;
	border-radius: 18px;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	background: $tc;
}