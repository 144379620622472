$wh: #fff !default;
$tc: #0952A5 !default;
$tc2: #222222 !default;
$tcl: #1964B8 !default;
$dg: #9e9e9e !default;
$lg: #f5f5f5 !default;
$gry: #eeeeee !default;

$y: #fdc10f !default;
$r: #eb5757 !default;
$g: #2bc067 !default;

$label-new: $g !default;
$label-sale: $r !default;

$f: 'Montserrat', sans-serif !default;
$f2: 'Open Sans', sans-serif !default;
$hf: 'Rubik', sans-serif !default;
$fa: "FontAwesome" !default;

/*z-index*/
$zi-header-menu: 100 !default;
$zi-mobile-menu: 10000 !default;
$zi-ocwall: 9000 !default;

$pagination-active-color: $tc2 !default;