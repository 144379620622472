@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
	#cart {
		margin-left: 15px;
	}
}

@media (max-width: $screen-md-max) {
	.page-home {
		#home-banner {
			#banner-bottom {
				.images-block {
					flex-wrap: wrap;
					.banner-item {
						@include size (calc(100% / 3), auto);
					}
				}
			}
		}
		#home-products {
			.products-block {
				#home-product- {
					&2, &4, &6 {
						background-size: cover;
					}
				}
			}
		}
	}

	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.mfilter-free-container {
		top: 174px;
	}

	.refine-search ul li a span, .refine-search ol li a span {
		line-height: 15px;
		font-size: 12px;
		overflow: hidden;
	}
} // 1199 px

@media (max-width: 1024px) {
	.nav-search {
		display: none;
	}
	.header-top {
		.container {
			#clone-search {
				display: block;
			}
		}

	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { // 768 px - 991 px
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 7px;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) { // 1199 px - 992 px
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

/*$media (max-width: 1199px) {*/
@media (max-width: $screen-md-max) {
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 3 - 20px);
				margin: 5px;
			}
			width: calc(100% / 3 - 20px);
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.header-top {
		height: 95px;
		.container {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			& > div:last-child {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: inherit;
			}
			#logo-theme {
				width: auto;
				margin-right: 150px;
				display: flex;
				height: inherit;
				align-items: center;
				.svg-icon {
					@include size(160px, 67px);
					svg {
						fill: $tc;
					}
				}
				a {
					text-align: left;
					display: flex;
					align-items: center;
					text-decoration: none;
					position: relative;
					span {
						font-style: normal;
						font-weight: 600;
						line-height: 21px;
						font-size: 13px;
						text-transform: uppercase;
						color: $tc;
						text-align: left;
					}
					& div:last-child {
						display: flex;
						margin-left: 0;
						position: absolute;
						bottom: 0;
						width: 100%;

						span {
							text-align: center;
							width: 100%;
						}
					}
				}
			}
			.topcontacts {
				display: flex;
				justify-content: center;
				.media-title {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin: 0 10px;
				}
				.media-body {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					width: max-content;
					font-size: 13px;
				}
			}
			.worktime {
				width: 190px;
				font-weight: 500;
				.media-body {
					span:last-child {
						letter-spacing: 0.22px;
					}
				}
			}
			.phones {
				width: 155px;
				margin-left: 10px;
				font-weight: 600;
				.media-body {
					span:first-child {
						letter-spacing: 0.7px;
					}
				}
			}
		}
	}

	.header-bottom {
		.container {
			.headercart {
				flex-direction: row-reverse;
			}
		}
	}

	#compare-count {
		.compare-link {
			padding-left: 0;
			margin-right: 8px;
		}
	}

	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 2 - 20px);
				margin: 5px;
			}
			width: calc(100% / 2 - 20px);
		}
	}

	#powered {
		.copyright {
			order: 2;
			text-align: right;
		}
		.payment {
			order: 1;
			text-align: center;

			.payment-img {
				float: none !important;

				img {
					&:first-child {
						display: none;
					}
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
	body .mfilter-free-container {
		top: 244px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		border-color: $tc2;
		&.mfilter-direction-rtl {
			margin-right: -222px;
		}
		#mfilter-free-container-0 {
			padding: 5px 10px !important;
			overflow: auto !important;
		}
		.mfilter-box {
			margin-bottom: 5px;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border: 1px solid $tc2;
			padding: 9px 5px;
			top: -1px;
			width: 33px;
			height: 23px;
			&:before {
				content: '\f1f3';
				font-family: $fa;
				color: $white;
				font-size: 28px;
				line-height: 23px;
				padding: 3px;
				left: -1px;
				position: relative;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 65px;
			min-width: 65px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li {
		.fa, a {
			color: $wh;
		}
		&:hover {
			a, .fa {
				color: $tc2;
			}
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 1px 15px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $tc;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 3 - 7px);
	}
	.page-product {
		#tab-specification {
			.single-attr {
				display: block;
				.attr-name {
					width: unset;
					max-width: unset;
					.underlined {
						display: none;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	.page-home {
		.certificate-banner {
			.background {
				.description {
					padding: 20px 40px;
				}
			}
		}
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 20px);
		}
	}
	.featured-category ul li {
		width: 100%;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	#tab-specification {
		.underlined {
			display: none;
		}
	}
	.h1_container {
		h1 {
			width: 100%;
			font-size: 20px;
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, & tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
				padding: 0 !important;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				color: $tc;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}

	#powered {
		ul {
			&.links {
				margin-bottom: 40px;
			}
		}
		.inner {
			flex-direction: column;

			.copyright {
				order: 2;
				padding-left: 0;
			}
			.payment {
				order: 1;
				.payment-img {
					max-width: 100%;
				}
			}
		}
	}
	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}
	.page-product-allproduct {
		#content {
			.refine-search {
				width: 100%;
				.subcat {
					display: flex;
					flex-wrap: wrap;
					li {
						margin-bottom: 10px;
					}
				}
			}
			.product-filter {
				width: 100%;
			}
		}
	}
	.page-category {
		#content {
			margin-top: 15px;
			.product-filter {
				width: 100%;
			}
			.panel.category {
				width: 100%;
				max-height: unset;
				.accordion {
					display: flex;
					flex-wrap: wrap;
					.accordion-group {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
	.page-checkout-buy #coupon_form {
		width: 100%;
		#coupon_error > div {
			margin: 15px 0;
		}
	}
}

@media (max-width: 637px) {
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
}

@media (max-width: 570px) {
	.fancy_holiday_works {
		width: 280px !important;
		.fancybox-skin {
			padding: 30px 10px !important;
			background-color: #fff;
			.fancybox-inner {
				width: 260px !important;
			}
			.holiday_table_wrap {
				font-size: 14px;
			}
			.holiday_table {
				td {
					font-size: 14px;
				}
			}
		}
	}
	.holiday_works {
		margin-top: 0;
	}
	#powered > .container {
		padding: 0;
		height: auto;
	}
	.breadcrumbs {
		padding: 15px;
		.container {
			padding: 0;
		}
	}
	.page-product {
		#review_btn {
			margin-left: 0;
		}
		#form-review-container {
			.form-group > div {
				flex-wrap: wrap;
			}
			.recaptcha_container, .btn-white {
				margin-left: 0;
			}
		}
	}
	.page-home {
		.certificate-banner {
			.background {
				min-height: auto;
				.description {
					display: flex;
					flex-wrap: wrap;
					justify-content: start;
					.title {
						padding-top: 20px;
						font-size: 16px;
						line-height: normal;
					}
					.text, .link {
						font-size: 13px;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}
	#cart {
		.icon-cart {
			padding: 0 10px;
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
}

@media (max-width: 560px) {
	.product-grid {
		.product-block {
			width: 100%;
		}
	}
}

/*------------------------------------
				payment
 -----------------------------------*/

/* All */
@media (min-width: $screen-lg-min) {
	#payment {
		p, span {
			font-size: 16px;
			line-height: 20px;
		}
	}
}

#payment {
	p, span {
		font-size: 16px;
		line-height: 20px;
	}
}

.well {
	padding: 0px !important;
	color: $tc;
	font-size: 16px;
}

#payment {
	h2 {
		text-transform: none;
	}
	li {
		color: $tc2;
		font-size: 16px;
		line-height: 20px;
	}
}

#content {
	.well > h1 {
		margin-top: 50px;
		margin-bottom: 0px;
		padding-bottom: 10px;
		position: relative;
		display: block;
	}
}

@media (max-width: 767px) {
	#payment .payment-name {
		margin-top: 20px;
	}
}

/* Cash */
#payment {
	.cash_left {
		padding: 0;
		padding-left: 30px;
	}
	.cash_right {
		padding: 0;
	}
	.cash {
		margin: 0;
		padding: 0px;
		padding-right: 0px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#payment .cash_left {
		padding: 0px 30px;
	}
}

/* No Cash */
#payment {
	.no-cash_left {
		padding: 0;
		padding-left: 30px;
		padding-bottom: 40px;
	}
	.no-cash_right {
		padding: 0;
	}
	.no-cash {
		margin: 0;
		padding: 0px;
		border-top: none;
		border-bottom: none;
		padding-right: 0px;
		margin-top: 70px;
	}
	.gr {
		display: block !important;
	}
	.payment_grid {
		display: -webkit-flex;
		-webkit-flex-wrap: wrap;
		display: flex;
		height: 100%;
		overflow: hidden;
		p {
			font-size: 13px !important;
		}
	}
	.no-cash_right .payment_grid .gr {
		width: 20%;
		padding: 10px;
		border-left: none;
		position: relative;
		background: #fff;
		top: 0;
	}
	.no-cash_right .payment_grid .gr .gr-inner {
		padding: 10px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		height: 100%;
		width: 100%;
		align-content: baseline;
	}
	&.apple .no-cash_right .payment_grid .gr {
		width: calc(100% - 1px);
	}
}

#feature1 {
	z-index: 50;
	left: -20%;
}

#feature2 {
	z-index: 40;
	left: -40%;
}

#feature3 {
	z-index: 30;
	left: -60%;
}

#feature4 {
	z-index: 20;
	left: -80%;
}

#feature5 {
	z-index: 20;
	left: -100%;
}

#payment {
	.payment_grid {
		.gr:first-child, .gr:last-child {
			border-left: none !important;
		}
		.gr:last-child {
			border-right: none !important;
		}
		.gr:last-child {
			border-right: none !important;
		}
		.gr img {
			margin: 0 0 10px 0;
			max-width: 65px;
			align-self: center;
		}
		.gr p {
			margin: 0;
			clear: both;
			line-height: 20px;
			text-align: center;
			width: 100%;
		}
	}
}

@media (max-width: 991px) {
	#payment {
		.cash_right img {
			margin-top: 30px;
		}
		.no-cash_left {
			padding: 0px 30px;
			padding-bottom: 40px;
		}
		.no-cash_right .payment_grid .gr {
			width: 100%;
			display: flex !important;
			align-items: center;
			border-right: none;
			border-left: none;
		}
		&.apple .payment_grid .gr {
			width: calc(100% - 1px);
		}
		.payment_grid {
			.gr:nth-child(-n+2) {
				border-bottom: none;
			}
			.gr:nth-child(3), .gr:nth-child(4) {
				border-bottom: none;
			}
			.gr p {
				display: inline;
			}
			.gr img {
				flex-shrink: 0;
			}
		}
	}
	#feature1, #feature2, #feature3, #feature4 {
		left: -100%;
	}
}

@media (max-width: 767px) {
	#payment {
		.cash_left, .no-cash_left {
			padding: 0;
		}
		.no-cash {
			margin-top: 20px;
		}
		.payment_grid .gr {
			width: 100%;
		}
		.no-cash_left {
			padding-bottom: 20px;
		}
	}
}

@media (max-width: 600px) {
	#payment .no-cash_right .payment_grid .gr {
		width: 100%;
	}
	#feature1, #feature2, #feature3, #feature4 {
		left: -100%;
	}
}

@media (max-width: 500px) {
	.payment_grid .gr {
		width: 100%;
	}
}

/* Bank card */
#payment {
	.bank-card_left {
		padding: 0;
		padding-left: 30px;
	}
	.bank-card_right {
		padding: 0;
		padding-right: 70px;
		padding-left: 70px;
	}
	.bank-card {
		margin: 0;
		padding: 0px;
		border-bottom: 1px solid #ededed;
		padding-right: 0px;
		padding-bottom: 50px;
		margin-top: 20px;
	}
	.bank-card_right__ul li, .webmoney_left__ul li {
		list-style-type: none;
		margin: 1px 0;
		padding: 0 0 14px 0;
	}
	.bank-card_right__ul li:last-child, .webmoney_left__ul li:last-child {
		border-left: 2px solid #fff;
	}
	.bank-card_right__ul {
		padding-left: 0;
		margin-top: 20px;
		margin-bottom: 65px;
	}
}

@media (max-width: 991px) {
	#payment .bank-card_left {
		padding: 0px 30px;
	}
}

@media (max-width: 767px) {
	#payment {
		.bank-card_left, .bank-card_right {
			padding: 0;
		}
		.bank-card {
			padding-bottom: 20px;
		}
		.bank-card_right__ul {
			margin-bottom: 20px;
		}
	}
}

/* Terminals */
#payment {
	.terminals {
		#lines {
			position: absolute;
			height: 100%;
			width: 100%;
			opacity: 0.4;
			#polyline {
				stroke: $tc;
				fill: none;
			}
			@media (max-width: $screen-sm-max) {
				display: none;
			}
		}
		.oplata_points {
			@media (max-width: $screen-sm-max) {
				display: none;
			}
		}
		.terminals-1_right .terminals-1_right__image img, .terminals-2_left .terminals-2_left__image img, .terminals-3_right .terminals-3_right__image img, .terminals-5_right .terminals-5_right__image img {
			display: inline-block;
		}
		.terminals-3_right::before {
			content: '';
			position: absolute;
			right: 8px;
			top: -55px;
			border: 245px solid transparent;
			border-right: 400px solid $tc;
			z-index: 1;
			@media (min-width: 1400px) {
				border: 30vh solid transparent;
				border-right: 31vw solid $tc;
			}
		}
		.terminals-1_left, .terminals-3_left, .terminals-4_right, .terminals-5_left {
			padding: 0;
			padding-left: 30px;
		}
		.terminals-1_right, .terminals-2_left, .terminals-3_right, .terminals-4_left, .terminals-5_right {
			padding: 0;
		}
		.terminals-1_right .terminals-1_right__image {
			text-align: right;
		}
		.terminals-2 {
			padding-top: 60px;
		}
		.terminals-2_left .terminals-2_left__image, .terminals-3_right .terminals-3_right__image, .terminals-5_right .terminals-5_right__image {
			text-align: center;
		}
		.terminals-2_right {
			padding: 0;
			padding-right: 70px;
			padding-top: 115px;
		}
		.terminals-3_left {
			padding-right: 40px;
			padding-top: 170px;
		}
		.terminals-4_left {
			padding-left: 35px;
		}
		.terminals-4_right {
			padding-right: 40px;
			padding-top: 175px;
		}
		.terminals-5 {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.terminals-5_left {
			padding-right: 140px;
			padding-top: 210px;
		}
	}
	.terminals {
		.terminals-1_left__description {
			padding-left: 60px;
			padding-top: 50px;
		}
		.terminals-2_right__header {
			padding-left: 45px;
		}
		.terminals-2_right__description {
			padding-left: 45px;
			padding-top: 10px;
		}
		.terminals-3_left__header {
			padding-left: 65px;
		}
		.terminals-3_left__description {
			padding-left: 65px;
			padding-top: 10px;
		}
		.terminals-4_right__header {
			padding-left: 115px;
		}
		.terminals-4_right__description {
			padding-left: 115px;
			padding-top: 10px;
		}
		.terminals-5_left__header {
			padding-left: 65px;
		}
		.terminals-5_left__description {
			padding-left: 65px;
			padding-top: 10px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#payment {
		.terminals {
			.terminals-1_left__description {
				padding-left: 50px;
				padding-top: 10px;
			}
			.terminals-2_right {
				padding-top: 90px;
				padding-right: 60px;
			}
			.terminals-3_left {
				padding-top: 140px;
			}
			.terminals-3_left__header {
				padding-left: 50px;
			}
			.terminals-3_left__description {
				padding-left: 50px;
			}
			.terminals-4_right {
				padding-top: 140px;
			}
			.terminals-4_right__header {
				padding-left: 90px;
			}
			.terminals-4_right__description {
				padding-left: 90px;
			}
			.terminals-5_left {
				padding-top: 170px;
				padding-right: 70px;
			}
			.terminals-5_left__header {
				padding-left: 50px;
			}
			.terminals-5_left__description {
				padding-left: 50px;
			}
			.terminals-3_right::before {
				content: '';
				position: absolute;
				right: 8px;
				top: -55px;
				border: 175px solid transparent;
				border-right: 315px solid $tc2;
				z-index: 1;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#payment {
		.terminals {
			background: none;
			.terminals-3_right::before {
				border: none;
				display: none;
			}
			.terminals-1_left__description {
				padding-left: 30px;
				padding-top: 0px;
			}
			.terminals-2 {
				padding-top: 30px;
				padding-bottom: 30px;
			}
			.terminals-2_right {
				padding: 0;
				padding-right: 25px;
				padding-top: 90px;
			}
			.terminals-2_right__header {
				padding-left: 30px;
			}
			.terminals-2_right__description {
				padding-left: 30px;
			}
			.terminals-3_left {
				padding-top: 60px;
				padding-right: 10px;
			}
			.terminals-3_left__header {
				padding-left: 30px;
			}
			.terminals-3_left__description {
				padding-left: 30px;
			}
			.terminals-4_right {
				padding-top: 55px;
			}
			.terminals-4_right__header {
				padding-left: 60px;
			}
			.terminals-4_right__description {
				padding-left: 60px;
			}
			.terminals-5_left {
				padding-top: 70px;
				padding-right: 10px;
			}
			.terminals-5_left__header {
				padding-left: 30px;
			}
			.terminals-5_left__description {
				padding-left: 30px;
			}
		}
	}
}

@media (max-width: 767px) {
	#payment {
		.terminals {
			background: none;
			.terminals-3_right::before {
				border: none;
				display: none;
			}
			.terminals-1_left, .terminals-3_left, .terminals-4_right, .terminals-5_left, .terminals-3_right {
				padding: 0;
			}
			.terminals-1_left__description {
				padding-left: 0;
				padding-top: 0;
			}
			.terminals-2 {
				padding-top: 30px;
			}
			.terminals-2_right {
				padding: 30px 0 10px 0;
			}
			.terminals-2_right__header, .terminals-2_right__description, .terminals-3_left__header, .terminals-3_left__description, .terminals-4_right__header, .terminals-4_right__description, .terminals-5_left__header, .terminals-5_left__description {
				padding-left: 0;
			}
			.terminals-3_right {
				padding-top: 10px;
				padding-bottom: 25px;
			}
			.terminals-4_left {
				padding: 10px 30px;
			}
			.terminals-5 {
				padding-top: 20px;
			}
		}
	}
}

/* Yandex money */
#payment {
	.yandex-money_left {
		padding: 0;
		padding-left: 30px;
	}
	.yandex-money_right {
		padding: 0;
	}
	.yandex-money {
		margin: 0;
		padding: 0px;
		border-top: 1px solid $lg;
		border-bottom: 1px solid $lg;
		padding-right: 0px;
		padding-bottom: 50px;
		.yandex-money_right__blueBackground {
			padding: 34px 85px 35px 90px;
			background-color: $tc;
			line-height: 1.3em;
			margin-top: 12%;
			span {
				color: $wh;
			}
		}
		.yandex-money_right__logo {
			text-align: center;
			padding-top: 90px;
		}
		.yandex-money_left__mockup {
			text-align: right;
			padding-top: 30px;
		}
		.yandex-money_right__logo img, .yandex-money_left__mockup img {
			display: inline-block;
			max-width: 100%;
			height: auto;
		}
		.yandex-money_left__lastRow {
			padding-right: 35px;
			padding-top: 20px;
		}
		.yandex-money_left__lastRow__grayText {
			color: #b4b4b4;
		}
		.yandex-money_right__screen {
			padding-right: 10px;
		}
		.yandex-money_right__warning {
			margin-left: 0;
			margin-right: 0;
			display: flex;
			align-items: center;
			padding-left: 12%;
			padding-top: 30px;
		}
		.yandex-money_right__warning__icon {
			border-radius: 50px;
			background-color: $tc;
			padding: 10px 18px;
			color: $wh;
			font-weight: 600;
			margin-right: 30px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#payment {
		.yandex-money {
			.yandex-money_right__blueBackground {
				padding: 23px 35px 29px 30px;
			}
			.yandex-money_right__warning {
				padding-left: 0;
			}
			.yandex-money_left__lastRow {
				padding-right: 5px;
			}
		}
	}
}

@media (max-width: 991px) {
	#payment {
		.yandex-money_left {
			padding: 0px 30px;
		}
		.yandex-money {
			.yandex-money_right__logo {
				padding-top: 10px;
			}
			.yandex-money_left__mockup {
				text-align: center;
			}
			.yandex-money_right__blueBackground {
				padding: 15px 30px 15px 25px;
				margin-top: 0px;
			}
			.yandex-money_right__warning {
				padding-left: 17%;
				padding-top: 10px;
				padding-right: 10px;
			}
			.yandex-money_left__lastRow {
				padding-top: 0px;
				padding-bottom: 20px;
			}
		}
		.yandex-money .yandex-money_right__warning__icon {
			padding: 10px 18px;
			color: $wh;
			font-weight: 600;
		}
	}
}

@media (max-width: 767px) {
	#payment {
		.yandex-money {
			.yandex-money_right__warning {
				padding-left: 15px;
			}
			.yandex-money_right__warning__icon {
				margin-right: 0px;
			}
			.yandex-money_right__warning .pull-left p {
				padding-left: 15px;
			}
			.yandex-money_right__screen {
				padding-right: 0px;
			}
		}
		.yandex-money_left, .yandex-money_right {
			padding: 0;
		}
	}
}

/* WebMoney */
#payment {
	.webmoney_left {
		padding: 0;
		padding-left: 30px;
	}
	.webmoney_right {
		padding: 0;
		padding-right: 70px;
		padding-left: 70px;
		padding-top: 50px;
	}
	.webmoney {
		margin: 0;
		padding: 0px;
		border-bottom: 1px solid $lg;
		padding-right: 0px;
		padding-bottom: 50px;
	}
	.webmoney_left__ul {
		padding-left: 50px;
		margin-top: 110px;
		margin-bottom: 65px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#payment {
		.webmoney_left__ul {
			padding-left: 0;
		}
	}
}

@media (max-width: 991px) {
	#payment {
		.webmoney_left {
			padding: 0px 30px;
		}
		.webmoney_left__ul {
			margin-top: 0px;
			margin-bottom: 10px;
		}
		.webmoney_right {
			padding-top: 10px;
		}
	}
}

@media (max-width: 767px) {
	#payment {
		.webmoney_right {
			padding-right: 30px;
			padding-left: 30px;
		}
		.webmoney_left__ul {
			padding-left: 0px;
		}
		.webmoney {
			padding-bottom: 10px;
		}
		.webmoney_left, .webmoney_right {
			padding: 0;
		}
	}
}

/* Credit */
#payment {
	.credit .credit_center {
		padding: 0;
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (max-width: 767px) {
	#payment {
		.credit .credit_center {
			padding-right: 0;
			padding-left: 0;
			div img {
				width: 100% !important;
			}
		}
	}
}

#payment {
	h2 {
		color: $tc2;
	}
}

/* Credit */
#payment {
	.bank-card_right__ul li, .webmoney_left__ul li {
		height: inherit;
		display: flex;
		border-left: 2px dotted $tc;
	}
	.oplata_points {
		height: inherit;
		position: relative;
		right: 12px;
	}
	.oplata_circle_point {
		width: 22px;
		height: 22px;
		border: 1px solid $tc;
		border-radius: 11px;
		color: $wh;
		background-color: $tc;
		text-align: center;
		font-weight: 600;
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.step {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		position: absolute;
		font-size: 18px;
	}
	.bank-card_right__ul li span,
	.webmoney_left__ul li span {
		position: relative;
		height: auto;
	}
	.row {
		.terminals-1 {
			.step {
				top: 40px;
				@media (max-width: $screen-lg-min) {
					top: 10px;
				}
			}
		}
		.terminals-2, .terminals-3, .terminals-5 {
			.step {
				top: -6px;
			}
		}
		.terminals-4 {
			.step {
				top: -10px;
				left: 65px;
				@media (max-width: $screen-lg-min) {
					left: 40px;
				}
			}
		}
	}
}

/*-------------------------------------------------
						new
 ------------------------------------------------*/
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 0;
		font-size: 14px;
		& > span {
			padding: 0 10px;
		}
	}
	#searchtop input.form-control, #clone-search input.form-control {
		width: 125px;
	}
	.page-home #home-products .products-block .home-product .product-block .product-meta {
		padding-top: 80px !important;
	}
}

@media (min-width: $screen-sm-min) and (max-width: 1050px) {
	#mainmenutop .megamenu li a .menu-title {
		padding: 0 4px;
	}
}

@media (min-width: 851px) and (max-width: $screen-sm-max) {
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 0;
		font-size: 14px;
		& > span {
			padding: 0 10px;
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: 855px) {
	.pav-megamenu .navbar-nav > li > a {
		font-size: 13px;
	}
	.header-top .container {
		#logo-theme {
			.svg-icon {
				@include size (130px, 50px);
			}
			a span {
				font-size: 11px;
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	#is_ip {
		display: flex;
		width: 620px;
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.page-home {
		#banner-bottom {
			.images-block {
				.banner-item {
					padding-bottom: 20px;
				}
			}
		}
		#home-products {
			.products-block {
				.home-product.left {
					background-position-x: 60%;
				}
				.home-product.right {
					background-position-x: 35%;
				}
				#home-product-1 {
					background-position-x: 40%;
				}
				.home-product {
					height: 500px;
					.product-block {
						.product-meta {
							padding-top: 80px;
							.top {
								.name {
									margin: 0;
									font-size: 24px;
									line-height: 32px;
								}
								.description {
									font-size: 16px;
									line-height: 24px;
								}
							}
							.bottom {
								.price {
									font-size: 24px;
									.price-old {
										font-size: 16px;
										margin-right: -80px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.page-checkout-buy {
		h3 {
			margin: 50px 0 30px;
		}
		#buy_form_container {
			position: relative;
		}
		#cart-form-right {
			width: 40%;
			position: absolute;
			right: 0;
			bottom: 0;
			transform: translateY(calc(100% + 50px));
		}
		#customer-data {
			h3 {
				margin-top: 30px;
			}
		}
		.img-thumbnail {
			width: unset;
		}
		.products-form-heder, .products-form-body {
			.column-price, .column-image, .column-total {
				flex: 2 1;
			}
			.column-name {
				flex: 5 1;
			}
			.column-quantity {
				flex: 4 1;
			}
		}
	}
	.page-category {
		.h1_container h1 {
			margin: 0;
			padding-top: 15px;
			font-size: 30px;
			line-height: 45px;
		}
		#sidebar-main {
			padding: 0 5px;
			.filter-right {
				padding: 0 10px 20px 0;
				margin: 0;
				.sort {
					margin-right: 30px;
				}
			}
		}
	}
	.page-product {
		.product-specification {
			.first-specification-block {
				.list-unstyled {
					margin-bottom: 0;
					li {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 5px;
						span {
							font-size: 14px;
							width: 100%;
						}
					}
				}
				.invoice {
					margin-top: 0;
					margin-bottom: 5px;
				}
			}
			.second-specification-block {

			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	#compare-count {
		position: unset;
		.compare-link {
			padding-right: 35px;
			.icon {
				@include size(27px,27px);
				margin-right: 0;
			}
			.text-count {
				display: none;
			}
		}
	}
	.nav-search {
		display: block;
	}
	.header-top {
		height: 50px;
		.container {
			#logo-theme {
				width: 100%;

				.svg-icon {
					@include size(105px, 40px);
				}
			}
		}
	}
	.header-bottom {
		background-color: $tc;
		.container {
			justify-content: flex-end;
			.main-menu {
				flex: 1;
			}
			.nav-search #searchtop {
				padding-bottom: 0;
				input {
					background-color: $tc;
					color: $wh;
					font-size: 15px;
					padding: 10px 10px 5px;
					width: 0;
					@include opacity(0);
					visibility: hidden;
					position: absolute;
					right: 0;
					top: 3px;
					border-bottom: 1px solid $wh;
					&.active_input {
						width: calc(100vw - 350px);
						@include transition(all 0.4s ease 0s);
						border-bottom: 2px solid $wh;
						@include opacity(1);
						visibility: visible;
					}
				}
				button {
					border: none;
				}
			}
		}
		.svg-icon svg, #cart .icon-cart svg, .svg-icon {
			@include size(27px, 27px);
		}
		svg path {
			fill: $wh !important;
		}
		.mobile-phone-icon {
			padding: 0 35px;
		}
	}
	.page-category {
		.h1_container h1 {
			margin: 0;
			padding: 15px 0 30px;
			font-size: 22px;
			line-height: 26px;
		}
		#sidebar-main {
			padding: 0 5px;
			.filter-right {
				margin-bottom: 20px;
				.sort {
					margin-right: 10px;
				}
			}
		}
	}
	.page-home {
		#home-products .row-inner > div {
			padding: 0;
		}
		#home-banner {
			#banner-top {
				h1 {
					padding-bottom: 15px;
					font-size: 22px;
					line-height: 26px;
				}
				p {
					width: 80%;
					font-size: 16px;
					line-height: 24px;
				}
			}
			#banner-bottom {
				.images-block {
					.banner-item {
						@include size (calc(100% / 2), auto);
					}
				}
			}
		}
		#home-products {
			.products-block {
				.home-product {
					height: unset;
					.product-block {
						.block-img {
							display: none;
						}
						.product-meta {
							width: 100% !important;
							padding: 20px 40px 40px;
							& > div {
								width: 100%;
							}
							.top {
								.name {
									font-size: 20px;
									line-height: 22px;
									padding-bottom: 15px;
								}
								.description {
									font-size: 16px;
									line-height: 24px;
								}
							}
							.bottom {
								display: flex;
								justify-content: space-between;
								flex-wrap: wrap;
								padding-top: 10px;
								.price {
									font-size: 20px;
									min-width: unset;
									margin-right: 0;
									padding: 5px 0;
									.price-new {
										margin-right: 15px;
									}
									.price-old {
										font-size: 16px;
										margin-right: 15px;
									}
								}
								.cart {
									padding: 5px 0;
								}
							}
						}
					}
				}
			}
		}
	}
	.page-product {
		#button-cart {
			width: 130px;
		}
		.prod_rating {
			display: flex;
			flex-wrap: wrap;
			& > span, #scr_revs, #scr_newrev {
				margin-left: 0;
				margin-right: 20px;
			}
		}
		h1 {
			font-size: 22px;
			line-height: 26px;
		}
		.product-info {
			& > .row {
				.product-view {
					margin-bottom: 50px;
				}
				&:after {
					display: unset;
				}
			}
		}
		.product-specification {
			.first-specification-block, .second-specification-block {
				width: 100%;
			}
			#mobile-specification-block {
				display: flex;
				flex-wrap: wrap;
				.specification-block-inner {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					flex-wrap: wrap;
					&.shadow-box {
						padding: 0;
						box-shadow: unset;
					}
					.price-new {
						font-size: 20px;
						line-height: 22px;
					}
					.price {
						width: auto;
						margin-bottom: 0;
					}
					#button-cart {
						max-width: 170px;
					}
					@media (max-width: 460px) {
						br {
							display: none;
						}
					}
				}
			}
			#desctop-specification-block {
				display: none;
			}
		}
		.product-markdown-view {
			.cheaper-products {
				width: 100%;
			}
			#mobile-specification-block {
				.cheaper-item {
					width: 100%;

					&.general {
						padding: 20px;
						align-items: flex-start;
						justify-content: flex-start;

						.invoice {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
	.page-checkout-buy {
		#cart-form-right {
			line-height: 20px;
			width: calc(100% - 30px);
			margin: 0 15px;
		}
		.products-form-heder {
			display: none;
		}
		.products-form-body {
			border-bottom: 1px solid $lg;
			&:last-child {
				border-bottom: none !important;
				margin-bottom: 20px;
			}
		}
		.mobile-products-form-heder {
			display: flex;
			font-weight: 600;
			text-align: left;
		}
		.products-form-heder, .products-form-body {
			flex-wrap: wrap;
			height: unset;
			font-size: 14px;
			& > div {
				padding-bottom: 10px;
			}
			.column-price, .column-total, .column-name, .column-quantity {
				flex: unset;
				width: 75%;
				margin: 0 0 0 auto;
				text-align: left;
				padding-left: 0;
			}
			.column-quantity {
				margin: 0 0 0 25%;
				.btn-block {
					max-width: unset;
					width: unset;
					justify-content: flex-start;
					.quantity-btn-group {
						padding: 0 10px;
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
			.column-image, .column-total-title {
				flex: unset;
				width: 25%;
				text-align: left;
				padding-right: 10px;
			}
		}
	}
	.success {
		width: 90%;
		margin-left: -45%;
		padding: 30px 10px 15px 10px;
		font-size: 14px !important;
		br {
			display: none;
		}
		.success-title {
			font-size: inherit;
		}
		.success-prod {
			font-size: inherit;
			.success-prod-img {
				width: 30%;
				padding: 0 15px 0 0;
				img {
					width: 100%;
				}
			}
			.success-prod-info {
				width: 70%;
				padding: 0 0 10px 0;
			}
		}
		.success-btn-group {
			flex-wrap: wrap;
			flex-direction: column-reverse;
			width: 70%;
			margin: 0 0 0 auto;
			& > div {
				text-align: left;
				width: 100%;
				padding-bottom: 20px;
			}
		}
		.close {
			top: 14px;
			right: 15px;
		}
		.success_button {

		}
	}
}

@media (max-width: $screen-xs) {
	.header-top {
		height: auto;

		.container {
			& > div:last-child {
				flex-direction: column;
			}
			#logo-theme {
				margin-right: 0;
			}
			.topcontacts {
				.media-body {
					line-height: 16px;
				}
			}
		}
	}
	.holiday_works {
		position: unset;
	}
}

@media (max-width: 400px) {
	.header-bottom {
		.mobile-phone-icon {
			padding: 0 10px;
		}
		.container .nav-search #searchtop input.active_input {
			width: calc(100vw - 200px);
		}
		#compare-count {
			.compare-link {
				padding: 0 10px;
			}
		}
	}
}

@media (min-width: 401px) and (max-width: 550px) {
	.header-bottom .container {
		.nav-search #searchtop input.active_input {
			width: calc(100vw - 250px);
		}
	}
}