.owl-carousel {
	display: block !important;
}

.h1_container {
	h1 {
		padding: 0;
		margin-top: 15px;
		margin-bottom: 0;
	}
}

.ocwall {
	opacity: 0;
	@include transition (opacity 400ms ease 0s);
}

.ocwall.active {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 10000;
	background: $tc2;
	opacity: 0.3;
	@include transition (opacity 400ms ease 0s);
}

.link-underline {
	font-size: 14px;
	color: $dg;
	text-decoration: underline;
	font-weight: 600;
	display: flex;
	align-items: center;
	.svg-icon {
		margin: 4px 5px 0 0;
	}
	&:hover {
		cursor: pointer;
	}
}

.success-link {
	color: $tc2;
	&:hover {
		color: $tc;
	}
}

.flex {
	display: flex;
	flex-wrap: wrap;
}

.btn-circle {
	svg path {
		color: $tc;
	}
}

body {
	font-family: $f;
	p, h1, h2, h3, h4, h5, a {
		font-family: $f !important;
	}
}

#tab-description {
	.product-particularities {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.particularity {
			margin: 0 0 30px 0;
			width: calc(100% / 3 - 20px);
			border-radius: 5px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
			.partic-img {
				width: 100%;
			}
			.partic-title {
				padding: 25px 20px 20px;
				font-size: 16px;
				line-height: 21px;
				font-weight: 600;
				margin: 0;
			}
			.partic-text {
				padding: 0 20px 20px;
				font-size: 14px;
				line-height: 21px;
			}
		}
	}
	.product-worth h3, .product-application h3 {
		margin-top: 50px;
		margin-bottom: 30px;
	}
	@media (max-width: 1100px) {
		.product-particularities .particularity {
			width: calc(100% / 2 - 15px)
		}
	}
	@media (max-width: 600px) {
		.product-particularities .particularity {
			width: 100%;
		}
	}
}

#behind-header {
	height: 145px;
	@media (max-width: $screen-xs-max) {
		height: 100px;
	}
	width: 100%;
}

#header-layout {
	position: fixed;
	z-index: 1000;
	background: $wh;
	top: 0;
	width: 100%;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.product-label {
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	z-index: 1;
	position: absolute;
	text-align: center;
	line-height: 17px;
	padding: 5px 15px;
	top: 0;
	border-radius: 50px;
	&.sale {
		background-color: $r;
		right: 0;
		color: $wh;
	}
	&.new {
		background-color: $g;
		left: 0;
		color: $wh;
	}
}

.filter-right {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0;
	margin-bottom: 30px;
	.sort {
		margin-right: 20px;
	}
	.display {
		border: 2px solid $lg;
		border-radius: 5px;
		height: 34px;
		display: flex;
		align-items: center;
		.btn-switch {
			@include size(40px, 30px);
			display: flex;
			justify-content: center;
			background-color: $lg;
			margin: 0;
			&.active {
				background-color: $wh;
			}
			#list-svg, #grid-svg {
				@include size(25px, 30px);
				.svg-switch {
					fill: $tc;
					fill-rule: evenodd;
				}
			}
		}
	}
}

.btn-switch {
	display: inline-block;
	margin-right: 5px;
	color: #969696;
	background: transparent;
	padding: 0;
	line-height: 35px;
	font-weight: 500;
	font-size: 34px;
	@include transition (all 200ms ease 0s);
	i {
		padding: 0 5px;
	}
	&:hover, &.active {
		color: $theme-color;
		@include box-shadow (none);
	}
	&#grid-switch {
		font-size: 32px;
	}
}

.product-info {
	@media (min-width: $screen-sm-min) {
		.product-view {
			padding-left: 0;
		}
	}
	.product-label {
		top: 10px;
		&.sale {
			right: 25px;
		}
		&.new {
			left: 25px;
		}
	}
	#product {
		.storage_widget {
			border: none !important;
			font-weight: normal !important;
			font-size: 14px !important;
			line-height: unset !important;
			& > span > b, & > div {
				font-size: inherit !important;
				font-weight: inherit !important;
				line-height: unset !important;
			}
			table {
				margin: 20px 0 !important;
				tr {
					& > td:first-child {
						display: none;
					}
					& > td:nth-child(2) {
						font-weight: 600;
						text-decoration: underline;
						padding-left: 0 !important;
						width: 280px;
					}
					& > td:last-child {
						text-align: left !important;
						span {
							background-color: $wh !important;
							color: $r !important;
							border: 1px solid $r !important;
							border-radius: 50px !important;
							&:hover {
								background-color: $r !important;
								color: $wh !important;
							}
						}
					}
				}
			}
		}
	}
	.advantages_block {
		display: none;
		flex-wrap: wrap;
		margin-top: 60px;
		width: 100%;
		.advantage_item {
			width: 50%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			min-width: 170px;
			div {
				@include size(40px, 40px);
				margin: 4px;
				.cl-1, .cl-2 {
					fill: $tc;
				}
				.cl-1 {
					opacity: 0.5;
					isolation: isolate;
				}
			}
			span {
				margin: 0 10px;
				font-size: 12px;
				text-align: center;
			}
		}
		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-block {
		display: flex;
		margin: 10px 0;
		border-radius: 5px;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		padding: 0;
		.image {
			img {
				@media (min-width: $screen-lg-min) {
					max-width: 285px !important;
				}
			}
		}
		.block-img {
			display: flex;
			width: 30%;
			padding: 25px;
			.product-label.sale {
				right: 20px;
			}
			.product-label.new {
				left: 20px
			}
		}
		.product-meta {
			display: flex;
			.top {
				display: flex;
				flex-wrap: wrap;
				padding: 25px 10px 25px 25px;
				border-right: 1px solid $lg;
				flex: 3 1;
				.rating {
					display: flex;
					justify-content: flex-start;
					width: fit-content;
					order: 2;
					margin-bottom: 10px;
					color: $y;
					font-size: 18px;
					max-height: 25px;
				}
				.name {
					order: 1;
					margin-top: 0;
					font-weight: 600;
					font-size: 18px;
					height: auto !important;
					width: 100%;
					a {
						span {
							display: inline-block;
							width: 100%;
							text-align: left;
							&.title1 {
								margin-bottom: 10px;
							}
							&.title2 {
								margin-bottom: 20px;
							}
						}
					}
				}
				.description {
					order: 3;
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					line-height: normal;
					font-size: 16px;
					color: #999999;
				}
			}
			.bottom {
				padding: 25px 25px 25px 10px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				flex: 1 1;
				.price {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
					padding: calc(70% - 50px) 0 20px 25px;
					.price-new {
						width: 100%;
						font-size: 26px;
						font-weight: 600;
						color: $tc2;
						text-align: left;
					}
					.price-old {
						width: 100%;
						font-size: 18px;
						text-align: left;
						text-decoration: line-through;
						color: $dg;
					}
				}
				.cart {
					width: 100%;
					display: flex;
					justify-content: flex-start;
					padding-left: 25px;
					.btn-circle {
						display: none;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.product-list {
		.product-block {
			.block-img {
				width: 350px;
				.image {
					padding: 10px 35px;
				}
				.quickview {
					right: unset;
					left: 295px;
				}
			}
		}
	}
}

.product-grid, .products-owl-carousel {
	width: 100%;

	&.product-related {
		.products-block {
			margin: 5px;
		}
		.product-block {
			width: calc(100% / 4 - 13px);
		}
	}
	.products-block {
		display: flex;
		flex-wrap: wrap;
	}
	.product-block {
		display: inline-block;
		margin: 10px;
		padding: 20px;
		width: calc(100% / 4 - 20px);
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		@include rounded-corners(5px);
		@include transition(border 0.2s ease-in-out);
		.cart {
			padding: 0;
			flex: 1 1 auto;
			.btn {
				width: 100%;
			}
		}
		.description {
			display: none;
		}
		.top {
			.rating {
				height: 0px;
				position: relative;
				top: -5px;
				width: 100%;
				text-align: center;
				.stars {
					color: $yellow;
					font-size: 18px;
				}
			}
			.name {
				font-size: 14px;
			}
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
			z-index: 999;
			background-color: $white;
			display: flex;
			.price {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				justify-content: center;
				width: 50%;
				.price-new {
					color: $tc2;
					font-size: 20px;
					font-weight: 600;
					width: 100%;
					text-align: left;
					@media (max-width: $screen-xs-max) {
						font-size: 17px;
					}
				}
				.price-old {
					text-decoration: line-through;
					color: $dg;
					font-size: 15px;
					width: 100%;
					text-align: left;
					@media (max-width: $screen-xs-max) {
						font-size: 13px;
					}
				}
			}
			.cart {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
			}
			&.no-stock {
				.cart {
					width: 50%;
					.btn-primary {
						height: 40px;
						background: #fff;
						color: #0952A5;
						padding: 0;
						font-size: 14px;
					}
				}
			}
		}
		&:hover {
			box-shadow: inset 0 0 0 2px $lg;
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

.product-block {
	.image {
		position: relative;
	}
}

/* NEW */

/*home page*/
@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
	.page-home {
		.main-column {
			padding: 0;
			margin: 0;
			width: 100%;
		}
	}
}

.page-home {
	#home-banner {
		background: url('#{$cdn}catalog/home/banner/banner_bck.png') no-repeat;
		-moz-background-size: 100%;
		-webkit-background-size: 100%;
		-o-background-size: 100%;
		background-size: 100%;
		background-position: center top;
		height: auto;
		@media (max-width: 1550px) {
			-moz-background-size: 1550px;
			-webkit-background-size: 1550px;
			-o-background-size: 1550px;
			background-size: 1550px;
		}
		#banner-top {
			height: 370px;
			h1 {
				padding: 50px;
				margin: 0;
				font-style: normal;
				font-weight: 800;
				line-height: 45px;
				font-size: 30px;
				text-align: center;
				text-transform: uppercase;
				color: $tc2;
			}
			p {
				font-style: normal;
				font-weight: normal;
				line-height: 27px;
				font-size: 18px;
				text-align: center;
				color: $tc2;
				width: 700px;
				margin: 0 auto;
			}
		}
		#banner-bottom {
			height: fit-content;
			background-color: $wh;
			.images-block {
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
				position: relative;
				bottom: 75px;
				padding: 0 40px;
				.banner-item {
					@include size(calc(100% / 6), auto);
					display: flex;
					justify-content: center;
					.image-block {
						padding: 0 20px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						position: relative;
						img {
							-webkit-filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.24));
							filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.24));
						}
					}
					span {
						font-style: normal;
						font-weight: normal;
						font-size: 24px;
						line-height: 32px;
						text-align: center;
						text-transform: uppercase;
						color: $dg;
						width: 100%;
					}
					span:last-child {
						font-weight: 700;
					}
					& a:active, & a:focus {
						text-decoration: none;
					}
					&:hover {
						span {
							color: $tc2;
						}
						.image-block {
							top: -15px;
							img {
								-webkit-filter: drop-shadow(0px 15px 10px $sc);
								filter: drop-shadow(0px 15px 10px $sc);
							}
						}
					}
				}
			}
		}
	}
	#home-products {
		.products-block {
			width: 100%;
			.home-product {
				@media (min-width: 1501px) {
					@include size(inherit, 550px);
				}
				@include size(inherit, 450px);
				.product-block {
					padding: 0;
					.product-meta {
						@include size(60%, 100%);
						display: flex;
						align-items: center;
						padding: 120px 40px 40px;
						& > div {
							height: 100%;
							width: 100%;
						}
						.top {
							.name {
								width: 100%;
								margin: 0;
								padding-bottom: 35px;
								font-size: 26px;
								line-height: 32px;
								a {
									display: flex;
									text-decoration: none;
									color: inherit;
									&:hover {
										color: inherit;
										text-decoration: underline !important;
									}
								}
								.title1, .title2 {
									font-style: normal;
									font-weight: bold;
									font-size: 26px;
									line-height: 32px;
									text-transform: uppercase;
								}
							}
							.description {
								font-style: normal;
								font-weight: normal;
								font-size: 18px;
								line-height: 27px;
								width: 100%;
							}
							.more-info {
								font-style: normal;
								font-weight: 600;
								font-size: 14px;
								line-height: 21px;
								text-decoration-line: underline;
								width: 100%;
							}
						}
						.bottom {
							display: flex;
							padding: 20px 0px 10px;
							.price {
								display: flex;
								align-items: center;
								font-style: normal;
								font-weight: bold;
								font-size: 26px;
								line-height: 32px;
								text-transform: uppercase;
								margin-right: 100px;
								.price-old {
									font-size: 22px;
									text-decoration: line-through;
									margin-right: 20px;
									margin-left: 20px;
								}
							}
						}
					}
				}
				&.right {
					.product-block {
						display: flex;
						justify-content: flex-end;
						.product-meta {
							text-align: left;
							.top {
								.name {
									a {
										display: flex;
										text-decoration: none;
									}
								}
							}
							.bottom {
								display: flex;
								padding-top: 20px;
							}
						}
					}
				}
				&.left {
					.product-block {
						.product-meta {
							.top {
								.name {
									a {
										display: flex;
										text-decoration: none;
									}
								}
							}
							.bottom {
								display: flex;
								padding-top: 20px;
							}
						}
					}
				}
				&.light {
					.product-block {
						.product-meta {
							.top {
								.name {
									a {
										color: $tc2;
									}
									.title1, .title2 {
										color: $tc2;
									}
								}
								.description {
									color: $tc2;
								}
								.more-info {
									color: $tc2;
								}
							}
							.bottom {
								.price {
									color: $tc2;
								}
							}
						}
					}
				}
				&.dark {
					background-color: $tc2;
					.product-block {
						.product-meta {
							.top {
								.name {
									a {
										color: $wh;
									}
									.title1, .title2 {
										color: $wh;
									}
								}
								.description {
									color: $wh;
								}
								.more-info {
									color: $wh;
								}
							}
							.bottom {
								.price {
									color: $wh;
								}
							}
						}
					}
				}
				.products-block {
					.product-block {
						@include size(inherit, inherit);

					}
				}
			}
			.home-product,
			.home-product .mobile-bck {
				background-position: center;
				background-repeat: no-repeat;
			}
			@media (min-width: $screen-sm-min) {
				#home-product-1 {
					background-image: url('#{$image-cdn-path}products-home/288_bck.png');
				}
				#home-product-2 {
					background-image: url('#{$image-cdn-path}products-home/268_bck.png');
				}
				#home-product-3 {
					background-image: url('#{$image-cdn-path}products-home/198_bck.jpg');
				}
				#home-product-4 {
					background-image: url('#{$image-cdn-path}products-home/7_bck.jpg');
				}
				#home-product-5 {
					background-image: url('#{$image-cdn-path}products-home/298_bck.jpg');
				}
				#home-product-6 {
					background-image: url('#{$image-cdn-path}products-home/668_bck.png');
				}
			}
		}
	}
	.certificate-banner {
		.background {
			background-repeat: no-repeat;
			min-height: 250px;
			background-size: cover;
			background-position: center;
			.description {
				svg {
					width: 25px;
					height: 25px;
					vertical-align: middle;
				}
				.title {
					line-height: 45px;
					font-size: 30px;
					font-weight: bold;
					padding-top: 60px;
					text-transform: uppercase;
				}
				.text, .link {
					line-height: 27px;
					font-size: 18px;
				}
				.link {
					a {
						color: #0952A5;
					}
				}
				& > div {
					margin-bottom: 10px;
					width: 100%;
				}
			}
		}
	}
}

.page-information {
	p, li {
		font-size: 16px;
		line-height: 22px;
	}
}

/* cart page */

.page-checkout-buy {
	label {
		color: $tc2;
		font-weight: 400;
	}
	.products-form-heder, .products-form-body {
		display: flex;
		.column-image, .column-price, .column-total {
			flex: 3 1;
		}
		.column-name {
			flex: 8 1;
			&:hover {
				cursor: pointer;
			}
		}
		.column-quantity {
			flex: 4 1;
		}
	}
	.img-thumbnail {
		width: 100px;
		max-height: 100px;
	}
	.products-form-heder {
		font-weight: 600;
		text-align: center;
		font-size: 16px;
		.column-name {
			text-align: left;
			padding: 0 10px;
		}
	}
	.mobile-products-form-heder {
		display: none;
	}
	.products-form-body {
		height: 120px;
		align-items: center;
		font-size: 16px;
		text-align: center;
		color: $tc2;
		font-weight: 600;
		.new, .special {
			display: none;
		}
		.column-image {
			font-size: 12px;
		}
		.column-name {
			font-size: 14px;
			font-weight: normal;
			text-align: left;
			padding-left: 10px;
		}
		.column-price {
			color: $dg;
		}
		.column-quantity {
			input {
				width: 75px;
			}
		}
		.btn-block {
			display: flex;
			justify-content: center;
			max-width: 140px;
		}
	}
	h3 {
		margin: 50px 0 30px;
	}
	.radio_custom {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
		.input-control {
			margin-left: 0;
		}
	}
	#customer-data .form-group {
		margin-bottom: 20px;
	}
	#button-order {
		margin-top: 40px;
	}
	.checkbox_custom {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	#buy_form_container {
		.products-form-heder {
			border-bottom: 1px solid $lg;
		}
		.products-form-body:last-child {
			border-bottom: 1px solid $lg;
		}
	}
	.cart-form-left {
		@media (min-width: 992px) {
			padding: 0 75px 0 0;
		}
	}
	#cart-form-right {
		font-size: 16px;
		line-height: 24px;
		.total-item {
			& > div {
				justify-content: space-between;
				align-items: center;
				min-height: 35px;
			}
			.product-count {
				font-size: 14px;
				color: $dg;
			}
			.total-value {
				font-size: 20px;
			}
			.blue-line {
				border-top: 2px solid $tc;
			}
		}
		.shipping {
			display: flex;
			& > div:first-child {
				max-width: 60%;
			}
		}
	}
	#coupon_form {
		width: 55%;
		h4 {
			position: relative;
			&:hover {
				color: $tc;
				cursor: pointer;
			}
			&:after {
				content: '';
				position: absolute;
				/* bottom: -20px; */
				margin: 3px 10px;
				border: 10px solid transparent;
				border-top: 10px solid #0952A5;
			}
		}
		h4.active:after {
			margin: -6px 10px;
			content: '';
			position: absolute;
			border: 10px solid transparent;
			border-bottom: 10px solid #0952A5;
		}
		#coupon_container {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.form-group {
				width: calc(100% - 200px);
				margin-right: 20px;
				input {
					width: 100%;
				}
			}
		}
		#coupon_error {
			width: 100%;
		}
	}
}

.count-block {
	display: flex;
	align-items: center;
	input {
		@include size(80px, 25px);
		text-align: center;
		border: 1px solid $lg;
	}
	.add-action {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $lg;
		@include size(25px, 25px);
		&:hover {
			cursor: pointer;
		}
	}
	.add-down {
		border-radius: 5px 0 0 5px;
	}
	.add-up {
		border-radius: 0 5px 5px 0;
	}
}

input[type=text], textarea {
	resize: none;
	border-radius: 5px;
}

.tk-comment {
	margin: 10px 0 30px;
	.tk-info {
		font-size: 18px;
	}
	.tk-addr {
		font-weight: 600;
		font-size: 16px;
	}
}

.page-product-allproduct {
	.cat-name {
		figure {
			background: $lg;
			border: 1px solid $lg;
			box-sizing: border-box;
			border-radius: 42px;
			padding: 7px 15px;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			text-transform: uppercase;
			color: $tc;
			&:hover {
				color: $wh;
				background-color: $tc;
				cursor: pointer;
			}
		}
	}
	#content {
		display: flex;
		flex-wrap: wrap;
		margin-top: 25px;
		.refine-search {
			padding-left: 0;
			width: 60%;
			ul {
				padding-left: 0;
				margin: 0;
				li {
					padding-right: 20px;
					padding-left: 0;
					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
		.product-filter {
			width: 40%;
		}
	}
}

.page-category {
	#content {
		margin-top: 40px;
		display: flex;
		flex-wrap: wrap;
		.product-filter {
			width: 40%;
		}
		.panel.category {
			width: 60%;
			max-height: 37px;
			margin-bottom: 28px;
		}
		.content {
			width: 100%;
		}
	}
	.accordion {
		display: flex;
		.accordion-group {
			padding-right: 20px;
			padding-left: 0;
			&:last-child {
				padding-right: 0;
			}
			&:hover {
				background-color: unset;
			}
			.active {
				figure {
					color: $wh;
					background-color: $tc;
				}
			}
			figure {
				background: $lg;
				border: 1px solid $lg;
				box-sizing: border-box;
				border-radius: 42px;
				padding: 7px 15px;
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
				text-transform: uppercase;
				color: $tc;
				&:hover {
					color: $wh;
					background-color: $tc;
					cursor: pointer;
				}
			}
		}
	}
}

.page-information {
	.lcol, .rcol {
		width: 50%;
	}
	#all_info {
		h5 {
			font-size: 18px;
			font-weight: 600;
		}
	}
	.contacts_map {
		display: flex;
		flex-wrap: wrap;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
			h1 {
				text-align: left;
				margin-top: 20px;
			}
			ul {
				padding: 0;
				list-style: none;
			}
			li {
				padding: 20px 0 20px 65px;
				background: top 30px left no-repeat transparent;
				background-size: 40px auto;
				position: relative;
				span {
					font-size: 18px;
					line-height: 24px;
					display: block;
				}
				&:after {
					content: '';
					position: absolute;
					background: $wh;
					height: 1px;
					width: 75%;
					top: 3px;
				}
				&:first-child {
					&:after {
						display: none;
					}
				}
			}
			@for $i from 6 through 10 {
				li:nth-child(#{$i - 5}) {
					background-image: url(#{$cdn}/image/i/contact/#{$i}.PNG);
				}
			}
		}
		.rcol {
			height: 700px;
		}
	}
	.proezd {
		display: flex;
		flex-wrap: wrap;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
		}
		.rcol {
			background: url(/image/enter.jpg) center center no-repeat;
			background-size: cover;
			height: 430px;
		}
		h2 {
			font-size: 36px;
			text-align: left;
			margin-top: 40px;
			margin-bottom: 80px;
			font-weight: 600;
		}
		.ped, .avto {
			@include size(170px, 170px);
			background: $wh top 30px center no-repeat;
			background-size: 45px;
			text-align: center;
			display: inline-block;
			padding: 100px 10px 0 10px;
			font-size: 12px;
			vertical-align: bottom;
		}
		.ped {
			margin-left: 65px;
			margin-right: 30px;
			background-image: url(#{$cdn}/image/i/contact/ped.PNG)
		}
		.avto {
			background-image: url(#{$cdn}/image/i/contact/avto.PNG)
		}
	}
	.proezd2 {
		position: relative;
		padding-bottom: 75px;
		&:after {
			content: '';
			position: absolute;
			background: $wh;
			height: 1px;
			width: 85%;
			bottom: 0;
			left: 65px;
		}
		h5 {
			padding: 60px 0 35px 0;
			font-weight: 600;
		}
		p, h5 {
			font-size: 16px;
			line-height: 22px;
		}

	}
	form.content-contact {
		h2 {
			font-size: 36px;
			text-align: left;
			margin: 40px 0;
			font-weight: 600;
		}
		.button1 {
			float: right;
			color: $wh;
			font-size: 14px;
			position: absolute;
			bottom: 16px;
			right: 0;
			padding: 6px 52px;
		}
	}
	.form-horizontal .form-group {
		padding: 15px;
		margin: 0;
	}
	@media (max-width: 1199px) {
		.proezd {
			.ped {
				margin-left: 30px;
			}
		}
	}
	@media (max-width: 991px) {
		.proezd {
			.lcol div {
				margin: 0;
			}
		}
	}
	@media (max-width: 767px) {
		.contact-info > div {
			flex-wrap: wrap;
			#map {
				height: 400px;
			}
		}
		.lcol, .rcol {
			width: 100%;
		}
		.proezd {
			h2 {
				margin-bottom: 40px;
			}
			.lcol > div {
				width: 100%;
				height: auto;
				min-height: 50px;
				background-position: center left 5px;
				margin-bottom: 10px;
				text-align: left;
			}
			.ped {
				padding: 20px 10px 20px 60px;

			}
			.avto {
				padding: 10px 10px 10px 60px;
			}
		}
		.proezd2 {
			padding-bottom: 40px;
			h5 {
				padding: 30px 0 15px 0;
			}
		}
		form.content-contact .button1 {
			position: static;
		}
	}
}