.breadcrumb {
	> li {
		display: inline-block;
		&:first-child {
			a span {
				font-size: 16px;
				line-height: 1;
				color: $dg;
			}
		}
		+ li:before {
			content: "#{$breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 5px;
			color: $dg;
		}
		.fa {
			color: $dg;
		}
	}
	> .active {
		font-weight: 600;
	}
	> li a {
		span {
			color: $dg;
		}
		&:hover {
			span, .fa {
				color: $tc2;
			}
		}
	}
	> li span {
		color: $dg;
	}
}