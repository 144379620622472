#mainmenutop {
	.megamenu {
		li {
			a {
				padding: 14px 0;
				.menu-title {
					color: $tc2;
					padding: 0 10px;
					border-left: 1px solid $tc2;
					&:hover {
						color: $tc;
					}
				}
			}
			&:first-child {
				.menu-title{
					border-left: none;
					padding-left: 0;
				}
			}
		}
	}
}


.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		> li {
			height: 50px;
			&.parent {
				a {
					padding-right: 28px;
				}
			}
			&.aligned-left {
				.dropdown-menu {
					left: -1px;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
			}
			&:hover,
			&:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
			}
			> a {
				padding: 14px 18px;
				font-size: $font-size-base - 1;
				font-weight: 600;
				font-family: $hf;
				text-transform: uppercase;
				background-color: transparent !important;
				@include transition(all 200ms ease-out);
				line-height: 22px;
				&:hover, &:focus, &:active {
					color: $tc2;
					.caret:before {
						color: $tc2 !important;
					}
				}
				.caret {
					border: 0;
					position: relative;
					&:before {
						@include transition(all 200ms ease-out);
						content: '\f110';
						font-family: $fa;
						position: absolute;
						right: -10px;
						top: -12px;
						font-size: $font-size-base - 1;
						font-weight: 100;
						color: $lg;
					}
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	/* level 2 */
@media (max-width: $screen-xs-max) {
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		height: auto;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		background-color: $tc;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		ul, ol {
			padding: 0;
		}
		li {
			padding: 0;
			line-height: 20px;
			list-style: none;
			position: relative;
			a {
				padding: 12px 20px;
				display: inline-block;
				width: 100%;
				text-transform: uppercase;
				font-size: $font-size-base - 2;
				font-weight: 600;
				&:hover {
					background-color: transparent;
					color: $tc2;
					text-decoration: none;
				}
			}
			&.haschildren {
				&:after {
					position: absolute;
					content: '\f112';
					font-family: $fa;
					font-size: 15px;
					@include size(10px, 17px);
					right: 8px;
					top: 12px;
				}
				&:hover:after {
					background-position: center -17px;
				}
			}
			> ul.children {
				top: 0px;
				left: 285px;
				right: -250px;
				position: absolute;
				display: none;
				z-index: 99;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
				> li {
					a {
						padding: 8px 12px;
						border-bottom: 1px #ebebeb solid;
					}
					&:hover {
						background: $tc;
						a {
							color: $tc2;
						}
					}
					&:last-child {
						border: none;
					}
				}
			}
			&:hover {
				> ul.children {
					left: 100%;
					display: block;
					top: 0;
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $tc2;
				}
			}
		}
	}
}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}