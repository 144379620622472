
/*Text Colors
------------------------------------*/
.text-default {
	color: $brand-default !important;
}

.text-primary {
	color: $brand-primary !important;
}

.text-info {
	color: $brand-info !important;
}

.text-danger {
	color: $brand-danger !important;
}

.text-warning {
	color: $brand-warning !important;
}

.text-success {
	color: $brand-success !important;
}

/*Background Colors
------------------------------------*/
.bg-primary {
	background-color: $brand-primary !important;
}

.bg-info {
	background: $brand-info !important;
}

.bg-danger {
	background: $brand-danger !important;
}

.bg-warning {
	background: $brand-warning !important;
}

.bg-success {
	background: $brand-success !important;
}

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 600;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bo-accordion-color;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $bo-accordion-background;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}

.breadcrumbs {
	padding: 15px 0;
	.breadcrumb {
		margin: 0;
		padding: 0 2px;
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
}

.breadcrumbs-left {
	h2 {
		float: none;
		text-align: left;
	}
	.breadcrumb-links {
		text-align: left;
	}
}

.heading {
	z-index: 1;
	position: relative;
	text-align: center;
	h1, h2, h3, h4 {
		text-transform: uppercase;
	}
	p, small {
		margin-bottom: 15px;
		display: inline-block;
	}
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
}

@mixin translateY($y) {
	-webkit-transform: translateY($y);
	-ms-transform: translateY($y); // IE9 only
	-o-transform: translateY($y);
	transform: translateY($y);
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #E4E3E3;
	padding: 50px;
	left: 50%;
	top: 22%;
	font-size: 16px;
	-webkit-box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22);
	-moz-box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22);
	box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22);
	border-radius: 5px;
	z-index: 15000;
	position: fixed;
	.success-title {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.success-prod {
		display: flex;
		.success-prod-img {
			width: 150px;
			img {
				width: inherit;
				padding: 5px;
			}
		}
		.success-prod-info {
			width: 100%;
			padding: 15px;
			.success-prod-name {
				font-size: 14px;
				font-weight: 600;
			}
			.success-prod-price {
				font-size: 20px;
				font-weight: 600;
			}
		}
	}
	.close {
		opacity: 1;
		float: right;
		position: absolute;
		top: 17px;
		right: 20px;
		@include size(20px,20px);
		cursor: pointer;
		svg {
			@include size(100%, 100%);
			stroke-width: 1px;
			stroke: $tc2;
			&:hover {
				stroke: $tc;
			}
		}
	}
	.success-btn-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.success_button {
		@include transition(0.2s all);
		border: none;
		display: inline-block;
		cursor: pointer;
		font-size: 15px;
		font-weight: 600;
		padding: 6px 24px;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			text-decoration: none !important;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}